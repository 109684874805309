import React, {useState} from 'react';
import { Link } from "gatsby"

const Navbar = ({isRoot}) => {

 	const [open, setOpen] = useState(false);

	const toggleMenu = () => setOpen(!open);
	const closeMenu = () => setOpen(false);

	return (
		<nav className='navbar is-fixed-top' role="navigation" aria-label="main navigation">
			<div className='container'>
				<div className="navbar-brand">
					<Link
            style={{ boxShadow: `none` }}
						className='navbar-item has-text-weight-semibold'
						to={`/`}
					>
						Viraj Sanghvi
					</Link> 

					<a role="button" className={'navbar-burger burger' + (open ? ' is-active' : '')} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onClick={toggleMenu}>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
					</a>
				</div>

				<div className={'navbar-menu is-uppercase is-size-7' + (open ? ' is-active' : '')}>
					<div className="navbar-end">
            <a
              style={{ boxShadow: `none` }}
              className='navbar-item'
              href={`/#experience`}
              onClick={closeMenu}
            >
              Experience
            </a> 

            <a
              style={{ boxShadow: `none` }}
              className='navbar-item'
              href={`/#adventures`}
              onClick={closeMenu}
            >
							Adventures
            </a> 
            
            <a
              style={{ boxShadow: `none` }}
              className='navbar-item'
              href={`/#analytics`}
              onClick={closeMenu}
            >
              Sports Analytics
            </a> 
            
            <a
              style={{ boxShadow: `none` }}
              className='navbar-item'
              href={`/#gallery`}
              onClick={closeMenu}
            >
              Photos
            </a> 
             
            <a
              style={{ boxShadow: `none` }}
              className='navbar-item'
              href={`/#blog`}
              onClick={closeMenu}
            >
              Ramblings
            </a> 
              
            <a
              style={{ boxShadow: `none` }}
              className='navbar-item'
              href={`/#makes`}
              onClick={closeMenu}
            >
              Makes
            </a> 
               
					</div>
				</div>
			</div>
		</nav>
  );
};


export default Navbar;
