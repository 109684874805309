import React from "react"
import { Link } from "gatsby"

//import { rhythm, scale } from "../utils/typography"

import Navbar from './navbar';
import Footer from './footer';

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRoot = location.pathname === rootPath;
  
  return (
    //<div
      //style={{
        //marginLeft: `auto`,
        //marginRight: `auto`,
        //maxWidth: rhythm(24),
        //padding: `${rhythm(1.5)} ${Reactnhythm(3 / 4)}`,
      //}}
    //>
		<React.Fragment>
			<Navbar isRoot={isRoot} />
			{children}
			{/*
      <header>{header}</header>
      <main>{children}</main>
*/}
			<Footer/>
		</React.Fragment>
  )
  //</div>
}

export default Layout
