import React from 'react';

const Footer = ({}) => {
	return (
    <footer className='footer'>
      <div className="content has-text-centered">
        © {new Date().getFullYear()} Viraj Sanghvi
      </div>
    </footer> 
  );
};

export default Footer; 
